<template>
  <div class="wa__container">
    <main class="mb-10">
      <v-row class="h-inherit">
        <!-- <v-col cols="12" md="6" class="position-relative d-none d-md-block">
          <div
            class="auth__image"
            :style="{ backgroundImage: `url(${image})` }"
          >
            <div class="profile__image--label text-uppercase position-absolute">
              our super model:
            </div>
            <div class="profile__image--text position-absolute">beckie max</div>
          </div>
        </v-col> -->
        <!-- <v-col cols="12" md="6"> -->
        <v-col cols="12">
          <div class="d-flex justify-space-between pa-2 pt-5">
            <BrandName />
            <v-chip label text-color="black" @click="$_changeLocalePage()">
              {{ $_getLocale() === "jp" ? "EN" : "JP" }}</v-chip
            >
          </div>
          <div class="wa__forgot">
            <divider
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
              size="login"
            ></divider>
            <v-form @submit.prevent="sendEmail" v-if="resetPasswordStep === 'sendEmail'">
              <div class="wa__forgot__form mt-12 px-3">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      :label="$_t('email')"
                      color="SonicSilver"
                      dense
                      outlined
                      v-model="form.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="flex-dicoration-column-reverse mt-1">
                  <v-col cols="6" class="wa__tile--link pl-0 d-flex justify-start">
                    <router-link
                      :to="{
                        name: 'login',
                        params: { locale: this.$_getlocaleParam() },
                      }"
                    >
                      <block-button
                        height="35"
                        class="btn-return float-left"
                        :text="$_t('returnToLogin')"
                        text-mode="text"
                        icon="WMi-left-open"
                      ></block-button>
                    </router-link>
                  </v-col>
                  <v-col cols="6" class="wa__tile--link d-flex justify-end">
                    <block-button
                      height="35"
                      class="btn-send site__button width-auto bg-transparent"
                      :text="$_t('send')"
                      :loading="sendCodeLoading"
                      type="submit"
                    ></block-button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
            <v-form
              @submit.prevent="checkVerifyCode"
              v-else-if="resetPasswordStep === 'verifyCode'"
            >
              <div class="wa__forgot__form mt-12 px-3">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      :label="$_t('verifyCode')"
                      color="SonicSilver"
                      v-model="form.code"
                      autofocus
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="mt-1">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-divider class="my-0"></v-divider>
                      <div class="d-flex align-center justify-space-between">
                        <div class="get__code text-uppercase">didn't get the code?</div>
                        <div class="send__code d-flex">
                          <div class="text-uppercase" :class="{'cursor-pointer':isActiveResendCode} " @click="resendForgetPassCode">send again &nbsp;</div>
                          <span v-if="!isActiveResendCode">AFTER {{resendCodeTime}}s</span>
                        </div>
                      </div>
                      <v-divider class="my-0"></v-divider>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-4">
                  <v-row>
                    <v-col cols="6" class="wa__tile--link pl-0">
                      <block-button
                        height="35"
                        class="btn-return float-left"
                        :text="$_t('back')"
                        text-mode="text"
                        icon="WMi-left-open"
                        @click.native="returnToEmailResetPass"
                      ></block-button>
                    </v-col>
                    <v-col cols="6" class="wa__tile--link">
                      <block-button
                        height="35"
                        class="btn-send site__button width-auto bg-transparent"
                        :text="$_t('verifyCode')"
                        :loading="veryCodeLoading"
                        type="submit"
                      ></block-button>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-form>
            <ResetPasswordSection
              @goBack="
                () => {
                  resetPasswordStep = 'verifyCode';
                }
              "
              :form="form"
              v-else-if="resetPasswordStep === 'resetPassword'"
            />
          </div>
        </v-col>
      </v-row>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
const RESEND_CODE_TIME = 60;
import {password} from "@/mixins/validate"
import AuthRepository from "../abstraction/repository/authRepository";
import ResetPasswordSection from "./ResetPasswordSection";
import ResetPasswordImage from "../assets/47cddd0849f5845fbbf9026e22736b9a.jpg";
import BrandName from "../components/Global/Header/BrandName.vue";
import { mapActions,mapMutations } from "vuex";
let authRepository = new AuthRepository();
export default {
  components: {
    BrandName,
    ResetPasswordSection,
  },
  data: () => ({
    image: ResetPasswordImage,
    resetPasswordStep: "sendEmail",
    form: {},
    sendCodeLoading: false,
    veryCodeLoading: false,
    resendCodeInterval: null,
    resendCodeTime: 0,
    isActiveResendCode : false,
  }),
  methods: {
    ...mapMutations('user', ['SET_MY_INFO']),
    ...mapActions("auth", ["emailVerify"]),
    resendCodeTimer(){
      this.resendCodeTime = RESEND_CODE_TIME;
        this.isActiveResendCode = false;
      clearInterval(this.resendCodeInterval);
      this.resendCodeInterval = setInterval(() => {
        if(--this.resendCodeTime === 0){
          clearInterval(this.resendCodeInterval);
          this.isActiveResendCode = true;
        }
      }, 1000);
    },
    async resendForgetPassCode(){
      try {
        if(this.isActiveResendCode){
          await authRepository.forgetPasswordResendCode(this.form);
          this.resendCodeTimer()
        }
      } catch (e) {
        return e;
      }
    },
    returnToEmailResetPass(){
      this.resetPasswordStep = 'sendEmail';
    },
    async sendEmail() {
      try {
        this.sendCodeLoading = true;
        await authRepository.forgetPassword(this.form);
        this.resetPasswordStep = "verifyCode";
        this.resendCodeTimer()
      } catch (e) {
        return e;
      } finally {
        this.sendCodeLoading = false;
      }
    },
    async checkVerifyCode() {
      try {
        this.veryCodeLoading = true;
        const response = await this.emailVerify(this.form);
        this.SET_MY_INFO(response.user)
        this.resetPasswordStep = "resetPassword";
      } catch (e) {
        return e;
      } finally {
        this.veryCodeLoading = false;
      }
    },
  },
  computed: {
    password,
  },
  destroyed(){
    clearInterval(this.resendCodeInterval);
  }
};
</script>
<style scoped>
.cursor-pointer{
  cursor:pointer;
}
.wa__tile--link a {
  text-decoration: none;
  display: block;
  color: initial;
}
.wa__container {
  max-width: 100% !important;
  padding: 0 !important;
}
.h-inherit {
  min-height: inherit;
}
.get__code,
.send__code {
  font-size: 18px;
}
.get__code {
  color: #777777;
}
.wa__forgot {
  width: 100%;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  margin-top: 80px;
  text-align: center;
}
.wa__forgot__form {
  width: 25%;
  margin: 0 auto;
}
.btn-send {
  font-size: 14px !important;
  padding: 0 60px !important;
  letter-spacing: 3px !important;
  float: right;
}
.btn-return {
  font-size: 15px !important;
  letter-spacing: 3px !important;
}
.auth__image {
  height: 100%;
  background-size: cover;
  background-position: center;
}
.profile__image--text {
  bottom: 50px;
  right: 70px;
  font-size: 17px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
  color: var(--color-white);
}
.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 10px;
  color: var(--color-white);
  bottom: 70px;
  right: 90px;
}
/* media query */
/* @media screen and (max-width: 1904px) {
  .flex-dicoration-column-reverse {
    flex-direction: column-reverse;
  }
} */
@media screen and (max-width: 1264px) {
  .wa__forgot__form {
    width: 80%;
  }
}
@media screen and (max-width: 960px) {
  .wa__forgot__form {
    width: 90%;
  }
  .btn-send {
    width: 80% !important;
  }
}
@media screen and (max-width: 600px) {
  .btn-return {
    font-size: 10px !important;
    letter-spacing: 2px !important;
  }
  .btn-send {
    font-size: 12px !important;
    padding: 0 60px !important;
    width: -webkit-fill-available;
    width: 100% !important;
    letter-spacing: 2px !important;
  }
}
</style>
